import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";

const theme = createTheme({
    palette: {
      primary: {
        main: "#00A43C",
        text: "#000",
        secondary_text: "#121828",
      },
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: "1.1rem",
            fontWeight: 600,
            textTransform: "inherit",
            "@media (max-width: 640px)": {
              fontSize: "0.777rem",
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            "@media (max-width: 640px)": {
              fontSize: "0.777rem",
            },
          },
          secondary: {
            "@media (max-width: 640px)": {
              fontSize: "0.777rem",
            },
          },
        },
      },
    },
  });

  const FacultieslListInfo = (props) => {
    const { t } = useTranslation();
    return (
      <ThemeProvider theme={theme}>
        {props.userData.UserToFaculties.length > 0? (
          <List>
            {props.userData.UserToFaculties.map((val, i) => 
            {
            return(
                
              <ListItem
                key={`facultet_${i}`}
                divider={props.userData.UserToFaculties.length - 1 !== i}
                sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
              >
                {val.Faculty != null&& 
                <ListItemText
                sx={{
                  width: {
                    xs: "100%",
                    md: "50%",
                  },
                  color: "primary.text",
                }}
                primary={val.Faculty.name}
              />
                
                }
                
                <ListItemText
                  sx={{
                    width: {
                      xs: "100%",
                      md: "50%",
                    },
                    color: "primary.secondary_text",
                  }}
                  secondary={`${val.Speciality.name && val.Speciality.name} ${
                    val.Specialization ? " / " + val.Specialization.name : ""
                  }${
                    val.Group
                      ? "/ (" + t("text.groupe") + "-" + val.Group.name + ")"
                      : ""
                  } ${val.corpEmail?`(${val.corpEmail})`:''}`}
                />
              </ListItem>
            )})}
          </List>
        ) : (
          t("text.noData")
        )}
      </ThemeProvider>
    );
  };

  export default FacultieslListInfo;