import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {OpenInfoDialog} from "./OpenInfoDialog";
import {useEffect, useState} from "react";
import {axiosRun} from "../utils/axiosWrap";
import {config} from "../config";
import {restoreAuthData} from "../slices/usersSlice";
import {useNavigate} from "react-router-dom";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

export default function Agreement() {
    const user = useSelector(state=>state.users.user, shallowEqual);
    const { t } = useTranslation();
    const [showInfoMsg, setShowInfoMSg] = useState(true);
    const navigate = useNavigate();
    const dispatch
        = useDispatch();
    const applyAgrement = async ()=>{
        const result = await axiosRun(config.apiLinks.applyAgreement, 'POST');
        const userItem = JSON.parse(localStorage.getItem('userInfo'));
        userItem.user = result;
        console.log("userItem", userItem);
        localStorage.setItem('userInfo', JSON.stringify(userItem));
        dispatch(restoreAuthData());
    }

    useEffect(()=>{
        if (user.user.agreement){
            navigate('/profile/info')
        }
    },[user, navigate])

    useEffect( ()=>{
        const check = async ()=>{
            await axiosRun( config.apiLinks.checkUserStudent, 'GET' );
        };
        check();
    }, [] )

    return (
        <Box>
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                '& > :not(style)': {
                    m: 1,
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            <OpenInfoDialog isOpen={showInfoMsg} text={t("agrement.infoText")} onYes={()=>setShowInfoMSg(false)} />
            <Paper elevation={3} sx={{pl: '3%', pr: '2%', pt: '3%', pb:'4%'}}>
                <Typography sx={{fontWeight:'bold', textAlign:'center', lineHeight: 1.2, pb: '1.2%'}} variant={"h6"}>ДЕКЛАРАЦІЯ АКАДЕМІЧНОЇ ДОБРОЧЕСНОСТІ </Typography>
                <Typography sx={{textAlign: 'left', lineHeight: 1.4}}>
                    Я, {`${user.user.firstName} ${user.user.lastName}`}, будучи здобувачем освіти Сумського національного аграрного університету, підтримуючи засади справедливості, зобов’язуюсь дотримуватися правил академічної доброчесності, які визначені чинним законодавством України, нормативними документами університету а саме не допускати:
                </Typography>
                <List sx={{pl:'4%'}}>
                    <ListItem>
                        <ListItemText primary="-виявляти нешанобливе та некоректне ставлення до осіб, що навчаються, представників викладацького складу та адміністрації;">
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="-запізнюватись на навчальні заняття та пропускати їх без поважних причин;">
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="-(під час проходження академічного (модульного або заліково-екзаменаційного) контролю використовувати джерела інформації (усні (підказки), письмові (роботи інших осіб), друковані (книги, методичні посібники), електронні(телефони, планшети), недозволені викладачем;">
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="-просити, надавати та одержувати допомогу від третіх осіб (у тому числі і в якості підставних) при проходженні поточного, модульного, семестрового і підсумкового контролю; використовувати родинні або службові зв'язки для отримання позитивної або вищої оцінки;">
                        </ListItemText>
                     </ListItem>
                    <ListItem>
                        <ListItemText primary="-здійснювати або заохочувати будь-якими способами зміну отриманої академічної оцінки;">
                        </ListItemText>
                    </ListItem>

                    <ListItem>
                        <ListItemText primary="-надавати для оцінювання письмову роботу, підготовлену за участю інших осіб, окрім випадків де підготовка передбачена певною групою;">
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="-вносити до списку авторів публікацій осіб, які не брали участі в отриманні наукових результатів">
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                    <ListItemText primary="-фальсифікувати або фабрикувати інформацію, наукові результати з їх наступним використанням роботі (курсовій, дипломній, дисертаційній); ">
                    </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="-отримувати або пропонувати хабар за отримання будь-яких переваг у навчальній або дослідницькій діяльності">
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                    <ListItemText primary="-брати участь у будь-якій іншій діяльності, пов'язаній з порушенням правил і норм академічної доброчесності.">
                    </ListItemText>
                    </ListItem>
                </List>
                <Typography sx={{pl:'2%'}}>
                    Підписуючи цей документ, я розумію, що за порушення правил академічної доброчесності до мене можуть бути притягнуті до таких форм відповідальності як:
                </Typography>
                <List sx={{pl:'4%', pt: '1%' }}>
                <ListItem>
                    <ListItemText primary={"• повторне проходження оцінювання (контрольної роботи, іспиту, заліку тощо)"}>
                    </ListItemText>
                </ListItem>
                    <ListItem>
                        <ListItemText primary={"• повторне проходження відповідного освітнього компонента;"}>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"•  позбавлення академічної стипендії;"}>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"• відрахування з університету"}>
                        </ListItemText>
                    </ListItem>
                </List>
                <Typography sx={{fontWeight: 'bold', pb: '10%'}}>
                    Підписанням цієї декларації стверджую своє нетерпиме ставлення до усіх форм та проявів академічної недоброчесності.
                </Typography>
            </Paper>
        </Box>
        <Box display={'flex'} justifyContent={"center"} >
            <Button startIcon={<ModeEditOutlineIcon />} variant="contained" onClick={applyAgrement}>
                {t('agrement.agreeApply')}
            </Button>
        </Box>
    </Box>
    );
}
