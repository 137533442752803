import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, MenuItem, Button, TableContainer, TableHead, TableRow, Paper, Stack } from '@mui/material';
import Select from "@mui/material/Select";
import { axiosRun } from "../utils/axiosWrap";
import { config } from "../config";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {shallowEqual, useSelector} from "react-redux";

const useStyles = makeStyles({
    tableContainer: {
        width: '100%'
    },
    tableHeaderCell: {
        fontWeight: 'bold',
    },
});

export const StudentTabEducation = (props) => {
    const classes = useStyles();
    const [student, setStudent] = useState({});
    const [groups, setGroups] = useState({});
    const [departments, setDepartments] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertData, setAlertData] = useState({});
    const studyTypes = {'1':{id:1, name:"Денна"},'2':{id:2, name: "Заочна"}};
    const roles = useSelector((state) => state.users.user, shallowEqual).user.roles;
    const activeStatuses = [{value: false, label: "no"},{value: true, label: "yes"} ]
    console.log("props faculties", props.faculties);

    useEffect(() => {
        async function fetchStudentData() {
            const linkDataGet = roles !== 'ADMIN_GROUP_LEAD'?config.apiLinks.getUserItem.replace("%id%",props.id.id):config.apiLinks.getUserItemGrLead.replace("%id%",props.id.id);
            const itemUser = await axiosRun(linkDataGet, 'GET')
            setStudent(itemUser);
            const groupsToFaculties = await axiosRun(roles!=='ADMIN_GROUP_LEAD'?config.apiLinks.getMappedGroups:config.apiLinks.getMappedGroupsGroupLead, 'GET');
            setGroups(groupsToFaculties);
            setDepartments(await axiosRun(config.apiLinks.getDepartmentsList,'GET'));
        }
        fetchStudentData();
        setIsLoad(false);
    }, []);

    const handleChangeStudyType = (event, inx) =>{
        const _studentTmp = {...student };
        const value = event.target.value;
        _studentTmp['UserToFaculties'][inx]['studyType'] = value;
        setStudent(_studentTmp);
    }

    const handleChangeFaculty = (event, newValue ,inx) => {
        const _studentTmp = { ...student };
        console.log("value new ", newValue);
        const value = newValue.value.split('-');
        _studentTmp['UserToFaculties'][inx] = { fid: value[0], sid: value[1], spid: value[2] ?? value[2] };
        setStudent(_studentTmp);
    }

    const handleChangeGroup = (event, newValue, inx) => {
        const _studentTmp = { ...student };
        _studentTmp['UserToFaculties'][inx]['groupId'] = newValue.id;
        setStudent(_studentTmp);
    }

    const handleChangeDepartment = (event, newValue, inx) =>{
        const _studentTmp = {...student};
        _studentTmp['UserToFaculties'][inx]['Department'] = newValue;
        setStudent(_studentTmp);
    }

    const handleChangeActive = (event, newValue, inx) =>{
        const _studentTmp = {...student};
        console.log(newValue);
        _studentTmp['UserToFaculties'][inx]['isActive'] = newValue.value;
        setStudent(_studentTmp);
    }

    const handleChangeLead = (event, inx) => {
        const _studentTmp = { ...student };
        _studentTmp['UserToFaculties'][inx]['isAdminThisGroup'] = event.target.value;
        setStudent(_studentTmp);
    }



    const handleAddNewRow = () => {
        const _studentTmp = { ...student };
        _studentTmp['UserToFaculties'][Object.keys(_studentTmp['UserToFaculties']).length]
            =
            { fid: null, sid: null, spid: null, groupId: null, studyFinish: null };
        setStudent(_studentTmp);
    }

    const save = async () => {
        const data = { 'id': student.id, UserToFaculties: student.UserToFaculties };
        const link = roles !== 'ADMIN_GROUP_LEAD'?config.apiLinks.updateStudyPlace:config.apiLinks.updateStudyPlaceGroupLead;
        try {
            console.log("data ", data);
            await axiosRun(link, 'PUT', data);
            setIsLoad(false);
            setTimeout( ()=>{
                setOpenAlert(true);
            }, 800 );
            setAlertData({type: 'success', message: 'update'})
        }catch (error){
            console.log(error)
            setTimeout( ()=>{
                setOpenAlert(true);
            }, 800 );
            setAlertData({type: 'error', message: error.message})
        }
    }

    const remove = async (inx) => {
        const _st = { ...student };
        delete _st.UserToFaculties[inx];
        setStudent(_st);
    }

    const responseAlert = (type, message) =>{
        return(
        <Snackbar
              open={openAlert}
              autoHideDuration={1000}
              onClose={() => {
                setOpenAlert(false);
              }}
            >
            <Alert variant="filled" severity={type}>
                {message}
            </Alert>
        </Snackbar>
        )
    }

    return (
        <>
         {isLoad && (
            <Box component="div" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: '#ffffffab', zIndex: 9 }}>
              <CircularProgress color="success" size={50} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
          </Box>
          )}
          { openAlert&&responseAlert(alertData.type, alertData.message)}
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                {/*<TableHead>*/}
                {/*    <TableRow>*/}
                {/*        <TableCell className={classes.tableHeaderCell} sx={{ width: '40%' }}>Faculty/Speciality/Specialization</TableCell>*/}
                {/*        <TableCell className={classes.tableHeaderCell} sx={{ width: '25%' }}>Group</TableCell>*/}
                {/*        <TableCell className={classes.tableHeaderCell} sx={{ width: '5%' }}>Additinal info</TableCell>*/}
                {/*        <TableCell className={classes.tableHeaderCell} sx={{ width: '20%' }} align='center'>ExitDepartment</TableCell>*/}
                {/*        <TableCell className={classes.tableHeaderCell} sx={{ width: '15%' }} align='center'>isActive</TableCell>*/}
                {/*        <TableCell className={classes.tableHeaderCell} sx={{ width: '5%' }} align='center'>Action</TableCell>*/}
                {/*    </TableRow>*/}
                {/*</TableHead>*/}
                <TableBody>
                    {Object.keys(groups).length > 0 && student.UserToFaculties && student.UserToFaculties.map((itmFaculty, inxFaculty) => {
                        console.log("props faculties ", props.faculties, itmFaculty.isActive, itmFaculty);
                        const curSelValue =  itmFaculty.spid ? itmFaculty.fid + '-' + itmFaculty.sid + '-' + itmFaculty.spid : itmFaculty.fid + '-' + itmFaculty.sid;
                        console.log("cur sel vakye", curSelValue);
                        const curItem = props.faculties.find(itm => itm.value === curSelValue);
                        const itemGroup = groups[curSelValue]?groups[curSelValue].find(item=>item.id === itmFaculty.groupId ):null;
                        return <TableRow sx={{bgcolor: inxFaculty % 2?'#E8F5EB':'#fff'}}>
                            <TableCell>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeaderCell} sx={{ width: '40%' }}>Faculty/Speciality/Specialization</TableCell>
                                            <TableCell className={classes.tableHeaderCell} sx={{ width: '25%' }}>Group</TableCell>
                                            <TableCell className={classes.tableHeaderCell} sx={{ width: '5%' }}>Additinal info</TableCell>
                                            <TableCell className={classes.tableHeaderCell} sx={{ width: '20%' }} align='center'>ExitDepartment</TableCell>
                                            <TableCell className={classes.tableHeaderCell} sx={{ width: '15%' }} align='center'>isActive</TableCell>
                                            <TableCell className={classes.tableHeaderCell} sx={{ width: '5%' }} align='center'>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableRow>
                                    <TableCell>
                                        <Autocomplete
                                            options={props.faculties}
                                            getOptionLabel={(item) => `${item.name}`}
                                            value={curItem}
                                            onChange={(event, newValue) => {
                                                handleChangeFaculty(event, newValue, inxFaculty);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Faculty spec specialization"/>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell >
                                        <Autocomplete
                                            options={groups[curSelValue]??[]}
                                            getOptionLabel={(item) => {
                                               const label = studyTypes[item.studyType]?`${item.name} (${studyTypes[item.studyType].name}) ${item.dateFinish??''} ${item.degree??''}`:`${item.name} ${item.degree??''} ${item.dateFinish??''}`;
                                                return label;
                                            }}
                                            value={itemGroup}
                                            onChange={(event, newValue) => {
                                                handleChangeGroup(event, newValue, inxFaculty);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Faculty spec specialization"/>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Box component='div' sx={{backgroundColor: "#fce4ec", width: '100%', height: '100%'}}>
                                            {itmFaculty.Degree?.name}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Autocomplete
                                            options={departments}
                                            getOptionLabel={(item) => {return item.name}}
                                            value={itmFaculty.Department}
                                            onChange={(event, newValue) => {
                                                handleChangeDepartment(event, newValue, inxFaculty);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Department exit"/>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {console.log("itm F isActive", itmFaculty.isActive)}
                                        <Autocomplete
                                            options={activeStatuses}
                                            value={activeStatuses.find(itm => itm.value === itmFaculty.isActive)}
                                            onChange={(event, newValue) => {
                                                handleChangeActive(event, newValue, inxFaculty);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="isActive"/>
                                            )}
                                        />
                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*    <Select*/}
                                    {/*        name={'group'}*/}
                                    {/*        value={itmFaculty.isAdminThisGroup}*/}
                                    {/*        onChange={(e, newValue) => handleChangeLead(e,inxFaculty)}*/}
                                    {/*        className={classes.select}*/}
                                    {/*        sx={{ width: '90%', minWidth: '150px'}}*/}
                                    {/*        MenuProps={{*/}
                                    {/*            sx:{maxHeight: '200px'}*/}
                                    {/*           }}*/}
                                    {/*    >*/}
                                    {/*        {*/}
                                    {/*            [{id:0, label: 'No'},{id:1, label: 'Yes'}].map(itm =><MenuItem value={itm.id} sx={{whiteSpace: 'break-spaces'}}>*/}
                                    {/*                {itm.label}*/}
                                    {/*            </MenuItem>)*/}
                                    {/*        }*/}
                                    {/*    </Select>*/}
                                    {/*</TableCell>*/}
                                    <TableCell align='center'>
                                        <IconButton onClick={e => remove(inxFaculty)} aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                    </TableRow>
                                    <TableRow colSpan={5}>
                                        <TableCell>{itmFaculty.corpEmail??"no corp email"}</TableCell>
                                    </TableRow>
                                </Table>
                            </TableCell>
                        </TableRow>
                    })}

                </TableBody>
            </Table>
            <Stack spacing={2} direction="row" sx={{justifyContent: 'end', p: 3}}>
                <Button onClick={handleAddNewRow} color='success' variant='contained'>
                    Add
                </Button>
                <Button onClick={()=>{
                    save();
                    setIsLoad(true);
                    
                }} color='primary' variant='contained' startIcon={<SaveIcon />}>Save</Button>
            </Stack>

        </TableContainer>
        </>
        
    );
}

